const Texts = {
  PT: {
    geral: "Organização Global",
    regional: "Organização Regional"
  },
  EN: {
    geral: "Global Organizers",
    regional: "Regional Organizers"
  }
}

export default Texts