// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import lucas from '../../assets/images/organizadores/2019/Lucas.jpg'
import gabriel from '../../assets/images/organizadores/2019/Gabriel.jpg'
import pedro from '../../assets/images/organizadores/2019/Pedro.jpg'
import victor from '../../assets/images/organizadores/2019/victor.jpg'
import julia from '../../assets/images/organizadores/2019/julia.jpg'
import camila from '../../assets/images/organizadores/2019/camila.jpg'

//Fotos Regional
import carlosManaus from '../../assets/images/organizadores/2020/carlosManaus.jpg'
import johnManaus from '../../assets/images/organizadores/2020/johnManaus.jpeg'
import jairo from '../../assets/images/organizadores/2019/JairoDublin.jpg'
import jose from '../../assets/images/organizadores/2019/JoseSaoLuis.jpg'
import erick from '../../assets/images/organizadores/2019/ErickPalmas.jpg'
import danielAracaju from '../../assets/images/organizadores/2019/DanielAracaju.jpg'
import khelson from '../../assets/images/organizadores/2019/KhelsonBelem.jpg'
import pamela from '../../assets/images/organizadores/2019/PamelaCuritiba.jpg'
import danilo from '../../assets/images/organizadores/2019/DaniloTeresina.jpg'
import gustavo from '../../assets/images/organizadores/2019/GustavoGoiania.jpg'
import danielRecife from '../../assets/images/organizadores/2019/DanielRecife.jpg'
import caio from '../../assets/images/organizadores/2019/CaioUberaba.jpg'
import felipe from '../../assets/images/organizadores/2019/FelipePatosDeMinas.jpg'
import narto from '../../assets/images/organizadores/2019/NartoNatal.jpg'
import helen from '../../assets/images/organizadores/2019/HelenRondonia.jpg'
import lucasBrasilia from '../../assets/images/organizadores/2019/LucasBrasilia.jpg'
import gabrielAndradeBrasilia from '../../assets/images/organizadores/2019/GabrielAndradeBrasilia.jpg'
import isaacBrasilia from '../../assets/images/organizadores/2019/IsaacBrasilia.jpg'
import gabrielMotaBrasilia from '../../assets/images/organizadores/2019/GabrielMotaBrasilia.jpg'
import guilhermeBrasilia from '../../assets/images/organizadores/2019/GuilhermeBrasilia.jpg'
import rodrigoBrasilia from '../../assets/images/organizadores/2019/RodrigoBrasilia.jpg'
import filipeCaruaru from '../../assets/images/organizadores/2019/FilipeCaruaru.jpg'
import arisonFortaleza from '../../assets/images/organizadores/2019/ArisonFortaleza.jpg'
import italoFortaleza from '../../assets/images/organizadores/2019/ItaloFortaleza.jpg'
import anayaSaoCarlos from '../../assets/images/organizadores/2019/AnayaSaoCarlos.jpg'
import kidManaus from '../../assets/images/organizadores/2019/KidManaus.jpg'
import hugoPorto from '../../assets/images/organizadores/2019/HugoPorto.jpg'
import ronica from '../../assets/images/organizadores/2019/Ronica.jpg'
import miguelLaPaz from '../../assets/images/organizadores/2019/MiguelLaPaz.png'
import ivanPortoAlegre from '../../assets/images/organizadores/2019/ivan.jpg'
import cedrikFloripa from '../../assets/images/organizadores/2019/CedrikRocha.png'
import mauCaxias from '../../assets/images/organizadores/2019/MauCaxias.png'
import viniciusSaoPaulo from '../../assets/images/organizadores/2019/ViniciusSaoPaulo.png'
import rangelPortoVelho from '../../assets/images/organizadores/2019/RangelPortoVelho.jpeg'
import alonsoSantiago from '../../assets/images/organizadores/2019/alonsoSantiago.jpg'
import matiasSantiago from '../../assets/images/organizadores/2019/matiasSantiago.jpeg'
import antonioSantiago from '../../assets/images/organizadores/2019/antonioSantiago.jpg'
import joaoBeloHorizonte from '../../assets/images/organizadores/2019/JoaoBeloHorizonte.jpeg'
import rodrigoRioGrande from '../../assets/images/organizadores/2019/RodrigoRioGrande.jpeg'
import sergioCochabamba from '../../assets/images/organizadores/2019/SergioCochabamba.jpg'
import daniloPresidentePrudente from '../../assets/images/organizadores/2019/DaniloPresidentePrudente.jpg'
import kledsonMaceio from '../../assets/images/organizadores/2019/kledsonMaceio.png'
import brunoJoaoPessoa from '../../assets/images/organizadores/2019/brunoJoaoPessoa.jpg'
import tutaleniNamibia from '../../assets/images/organizadores/2019/tutaleniNamibia.jpg'
import unoeste from '../../assets/images/organizadores/2019/unoeste.jpg'

const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, {
      nome: "Lucas Pessoa",
      foto: lucas
    }, {
      nome: "Gabriel Ferreira",
      foto: gabriel
    }, {
      nome: "Pedro Teixeira",
      foto: pedro
    }, {
      nome: "Victor Corrêa",
      foto: victor
    }, {
      nome: "Julia Suarez",
      foto: julia
    }, {
      nome: "Camila Fernandes",
      foto: camila
    }
  ],
  regional: [
    {
      nome: "Jairo Lopes",
      cidade: "Dublin",
      foto: jairo
    }, {
      nome: "Ronica Singh",
      cidade: "Durban",
      foto: ronica
    }, {
      nome: "Hugo Lopes",
      cidade: "Porto",
      foto: hugoPorto
    }, {
      nome: "Miguel Velasco",
      cidade: "La Paz",
      foto: miguelLaPaz
    }, {
      nome: "Alonso Canales",
      cidade: "Santiago",
      foto: alonsoSantiago
    }, {
      nome: "Matías Gabler",
      cidade: "Santiago",
      foto: matiasSantiago
    }, {
      nome: "Antonio Villamandos",
      cidade: "Santiago",
      foto: antonioSantiago
    }, {
      nome: "Sergio Arispe",
      cidade: "Cochabamba",
      foto: sergioCochabamba
    }, {
      nome: "Tutaleni Ilonga",
      cidade: "Windhoek",
      foto: tutaleniNamibia
    }, {
      nome: "José Nunes",
      cidade: "São Luís",
      foto: jose
    }, {
      nome: "Erick Henrique",
      cidade: "Palmas",
      foto: erick
    }, {
      nome: "Daniel Caldas",
      cidade: "Aracaju",
      foto: danielAracaju
    }, {
      nome: "Khelson Farias",
      cidade: "Belém",
      foto: khelson
    }, {
      nome: "Pâmela Beltrani",
      cidade: "Curitiba",
      foto: pamela
    }, {
      nome: "Danilo Carvalho",
      cidade: "Teresina",
      foto: danilo
    }, {
      nome: "Gustavo Christino",
      cidade: "Goiânia",
      foto: gustavo
    }, {
      nome: "Daniel Martins",
      cidade: "Recife",
      foto: danielRecife
    }, {
      nome: "Caio Melo",
      cidade: "Uberaba",
      foto: caio
    }, {
      nome: "Felipe Alvarenga",
      cidade: "Patos de Minas",
      foto: felipe
    }, {
      nome: "Narto Júnior",
      cidade: "Natal",
      foto: narto
    }, {
      nome: "Hélen Cristina",
      cidade: "Porto Velho",
      foto: helen
    }, {
      nome: "Rangel Miranda",
      cidade: "Porto Velho",
      foto: rangelPortoVelho
    }, {
      nome: "Lucas Caldeira",
      cidade: "Brasília",
      foto: lucasBrasilia
    }, {
      nome: "Gabriel Andrade",
      cidade: "Brasília",
      foto: gabrielAndradeBrasilia
    }, {
      nome: "Isaac Lopes",
      cidade: "Brasília",
      foto: isaacBrasilia
    }, {
      nome: "Gabriel Mota",
      cidade: "Brasília",
      foto: gabrielMotaBrasilia
    }, {
      nome: "Guilherme Okita",
      cidade: "Brasília",
      foto: guilhermeBrasilia
    }, {
      nome: "Rodrigo Maia",
      cidade: "Brasília",
      foto: rodrigoBrasilia
    }, {
      nome: "Filipe Rafael",
      cidade: "Caruaru",
      foto: filipeCaruaru
    }, {
      nome: "Arison Heltami",
      cidade: "Fortaleza",
      foto: arisonFortaleza
    }, {
      nome: "Italo Furtado",
      cidade: "Fortaleza",
      foto: italoFortaleza
    }, {
      nome: "Anayã Ferreira",
      cidade: "São Carlos",
      foto: anayaSaoCarlos
    }, {
      nome: "Kid Mendes",
      cidade: "Manaus",
      foto: kidManaus
    }, {
      nome: "Carlos Tapudima",
      cidade: "Manaus",
      foto: carlosManaus
    }, {
      nome: "Jonh Silva",
      cidade: "Manaus",
      foto: johnManaus
    }, {
      nome: "Ivan Sendin",
      cidade: "Porto Alegre",
      foto: ivanPortoAlegre
    }, {
      nome: "Cedrik Rocha",
      cidade: "Florianópolis",
      foto: cedrikFloripa
    }, {
      nome: "Mau Salamon",
      cidade: "Caxias do Sul",
      foto: mauCaxias
    }, {
      nome: "Vinicius Yokomizo",
      cidade: "São Paulo",
      foto: viniciusSaoPaulo
    }, {
      nome: "João Guilherme",
      cidade: "Belo Horizonte",
      foto: joaoBeloHorizonte
    }, {
      nome: "Rodrigo Grego",
      cidade: "Rio Grande",
      foto: rodrigoRioGrande
    }, {
      nome: "UNOESTE",
      cidade: "Presidente Prudente",
      foto: unoeste
    }, {
      nome: "Danilo Filitto",
      cidade: "Presidente Prudente",
      foto: daniloPresidentePrudente
    }, {
      nome: "Kledson Ferreira",
      cidade: "Maceió",
      foto: kledsonMaceio
    }, {
      nome: "Bruno Elias",
      cidade: "João Pessoa",
      foto: brunoJoaoPessoa
    }
  ]
}

export default dados