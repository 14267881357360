// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import felipe from '../../assets/images/organizadores/2019/FelipePatosDeMinas.jpg'
import juliana from '../../assets/images/organizadores/2020/juliana.jpeg'
// import palomaRecife from '../../assets/images/organizadores/2020/palomaRecife.jpg'
import pedrozambon from '../../assets/images/organizadores/2018/pedro.jpg'
import joaoLucas from '../../assets/images/organizadores/2020/joaoLucas.jpg'
import italoFortaleza from '../../assets/images/organizadores/2019/ItaloFortaleza.jpg'
import anna from '../../assets/images/organizadores/aqgj/Anna.jpg'
import jeanine from '../../assets/images/organizadores/2020/jeanine.jpeg'
import daviBennier from '../../assets/images/organizadores/2021/daviBennier.jpeg'
import leoBerman from '../../assets/images/organizadores/2020/leoBerman.jpg'
import leo from '../../assets/images/organizadores/aqgj/Leo.jpeg'
import cassio from '../../assets/images/organizadores/2020/cassio.jpeg'
import herbert from '../../assets/images/organizadores/aqgj/Herbert.jpg'
import klebiaNatal from '../../assets/images/organizadores/2020/klebiaNatal.jpg'

//Fotos Regional
import saiPrapanch from '../../assets/images/organizadores/2021/SaiPrapanch.jpg'
import rodolfoMora from '../../assets/images/organizadores/2021/RodolfoMora.jpg'
import miguelLaPaz from '../../assets/images/organizadores/2019/MiguelLaPaz.png'
import marcoSantoro from '../../assets/images/organizadores/2021/MarcoSantoro.jpg'
import rokKos from '../../assets/images/organizadores/2021/RokKos.jpg'
import joshuaDela from '../../assets/images/organizadores/2021/JoshuaDela.jpg'
import brunoFerrari from '../../assets/images/organizadores/2021/BrunoFerrari.jpg'
import amirsalarSolimani from '../../assets/images/organizadores/2021/AmirsalarSolimani.jpg'
import nicholasHaines from '../../assets/images/organizadores/2021/NicholasHaines.jpg'
import silvaniNeri from '../../assets/images/organizadores/2021/SilvaniNeri.jpg'
import danielleAkemi from '../../assets/images/organizadores/2021/DanielleAkemi.jpeg'
import marceloAnselmo from '../../assets/images/organizadores/2021/MarceloAnselmo.png'
import biancaPacheco from '../../assets/images/organizadores/2021/BiancaPacheco.jpeg'
import nayaraRaquel from '../../assets/images/organizadores/2021/NayaraRaquel.jpg'
import caelBorges from '../../assets/images/organizadores/2021/CaelBorges.jpg'
import matheusBorba from '../../assets/images/organizadores/2021/MatheusBorba.png'
import rafaelPassos from '../../assets/images/organizadores/2021/RafaelPassos.jpg'
import danielaMacedonia from '../../assets/images/organizadores/2021/danielaMacedonia.jpeg'
import alexanderFilipinas from '../../assets/images/organizadores/2021/alexanderFilipinas.jpeg'
import ricardoSalvador from '../../assets/images/organizadores/2021/ricardoSalvador.jpeg'
import melinaSalvador from '../../assets/images/organizadores/2021/melinaSalvador.jpeg'
import ceciliaSalvador from '../../assets/images/organizadores/2021/ceciliaSalvador.jpeg'
import karinaUberaba from '../../assets/images/organizadores/2021/karinaUberaba.jpeg'
import juliaMinas from '../../assets/images/organizadores/2021/juliaMinas.jpeg'
import bryanCostaRica from '../../assets/images/organizadores/2021/bryanCostaRica.jpeg'
import caioPatos from '../../assets/images/organizadores/2021/caioPatos.jpeg'
import linaCanada from '../../assets/images/organizadores/2021/linaCanada.png'
import sandraColombia from '../../assets/images/organizadores/2021/sandraColombia.jpeg'
import mwaabaZambia from '../../assets/images/organizadores/2021/mwaabaZambia.jpeg'
import idoIsrael from '../../assets/images/organizadores/2021/idoIsrael.jpg'
import davidNigeria from '../../assets/images/organizadores/2021/davidNigeria.jpeg'
import laurynAfrica from '../../assets/images/organizadores/2021/laurynAfrica.jpg'
import ronicaAfrica from '../../assets/images/organizadores/2021/ronicaAfrica.jpg'
import duartePortugal from '../../assets/images/organizadores/2021/duartePortugal.jpg'
import antonioPortugal from '../../assets/images/organizadores/2021/antonioPortugal.jpg'
import ivandroCaboVerde from '../../assets/images/organizadores/2021/ivandroCaboVerde.jpeg'
import yousefBahrain from '../../assets/images/organizadores/2021/yousefBahrain.jpg'
import albertoDF from '../../assets/images/organizadores/2021/albertoDF.png'
import harrietFrance from '../../assets/images/organizadores/2021/harrietFrance.jpeg'
import cedricFrance from '../../assets/images/organizadores/2021/cedricFrance.jpeg'
import aymericFrance from '../../assets/images/organizadores/2021/aymericFrance.jpeg'
import carlosManaus from '../../assets/images/organizadores/2021/carlosManaus.jpg'
import welintonMinas from '../../assets/images/organizadores/2021/welintonMinas.jpeg'
import annyPauloFrontim from '../../assets/images/organizadores/2021/annyPauloFrontim.jpeg'
import ivanRS from '../../assets/images/organizadores/2021/ivanRS.png'
// import pamRecife from '../../assets/images/organizadores/2021/pamRecife.jpeg'
import gabrielAssis from '../../assets/images/organizadores/2021/gabrielAssis.jpg'
import joaoRibamar from '../../assets/images/organizadores/2021/joaoRibamar.jpg'
import marcoPortoVelho from '../../assets/images/organizadores/2021/marcoPortoVelho.jpg'
import viniciusFortaleza from '../../assets/images/organizadores/2021/viniciusFortaleza.jpg'
import danielAracaju from '../../assets/images/organizadores/2021/danielAracaju.jpg'
import humbertoFloripa from '../../assets/images/organizadores/2021/humbertoFloripa.jpg'
import karinaPauloFrontim from '../../assets/images/organizadores/2021/karinaPauloFrontim.jpg'
import brunoCuritiba from '../../assets/images/organizadores/2021/brunoCuritiba.png'
import viniciusSP from '../../assets/images/organizadores/2021/viniciusSP.jpg'
import khelsonBelem from '../../assets/images/organizadores/2021/khelsonBelem.jpg'
import luisTeresina from '../../assets/images/organizadores/2021/luisTeresina.jpg'
import isabelPlaya from '../../assets/images/organizadores/2021/isabelPlaya.jpeg'
import isabellyManaus from '../../assets/images/organizadores/2021/isabellyManaus.jpeg'
import johnManaus from '../../assets/images/organizadores/2021/jonhManaus.jpeg'
import alicePauloFrontim from '../../assets/images/organizadores/2021/alicePauloFrontim.jpeg'
import annaPauloFrontim from '../../assets/images/organizadores/2021/annaPauloFrontim.jpeg'
import beatrizRecife from '../../assets/images/organizadores/2021/beatrizRecife.jpeg'
import rafaelRecife from '../../assets/images/organizadores/2021/rafaelRecife.jpeg'
import danieleRecife from '../../assets/images/organizadores/2021/danieleRecife.jpeg'
import tutaleniNamibia from '../../assets/images/organizadores/2021/tutaleniNamibia.jpg'

import rodrigoLisboa from '../../assets/images/organizadores/2020/rodrigoLisboa.jpg'



const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, 
    {
      nome: "Felipe Alvarenga",
      foto: felipe
    }, 
    {
      nome: "Juliana Brito",
      foto: juliana
    }, 
    // {
    //   nome: "Paloma Silva",
    //   foto: palomaRecife
    // }, 
    {
      nome: "Pedro Zambon",
      foto: pedrozambon
    }, 
    {
      nome: "João Lucas",
      foto: joaoLucas
    }, 
    {
      nome: "Italo Furtado",
      foto: italoFortaleza
    }, 
    {
      nome: "Anna Gianneli",
      foto: anna
    }, 
    {
      nome: "Jeanine Adler",
      foto: jeanine
    }, 
    {
      nome: "Davi Bennier",
      foto: daviBennier
    }, 
    {
      nome: "Leonardo Berman",
      foto: leoBerman
    }, 
    {
      nome: "Leonardo Mendes",
      foto: leo
    }, 
    {
      nome: "Cássio Amaral",
      foto: cassio
    },
    {
      nome: "Herbert Veloso",
      foto: herbert
    },
    {
      nome: "Klébia Karina",
      foto: klebiaNatal
    }, 
  ],
  regional: [
    {
      nome: "Sai Prapanch",
      cidade: "Atlanta",
      foto: saiPrapanch
    },
    {
      nome: "Rodolfo Mora-Zamora",
      cidade: "San José",
      foto: rodolfoMora
    },
    {
      nome: "Miguel Velasco",
      cidade: "La Paz",
      foto: miguelLaPaz
    },
    {
      nome: "Marco Santoro",
      cidade: "Barcelona",
      foto: marcoSantoro
    },
    {
      nome: "Rok Kos",
      cidade: "Ljubljana",
      foto: rokKos
    },
    {
      nome: "Joshua Dela Vega",
      cidade: "Manila",
      foto: joshuaDela
    },
    {
      nome: "Alexander Balingao",
      cidade: "Manila",
      foto: alexanderFilipinas
    },
    {
      nome: "Bruno Ferrari",
      cidade: "Juan Lacaze",
      foto: brunoFerrari
    },
    {
      nome: "Amirsalar Solimani",
      cidade: "Vancouver",
      foto: amirsalarSolimani
    },
    {
      nome: "Nicholas Haines",
      cidade: "Lawrence",
      foto: nicholasHaines
    },
    {
      nome: "Daniela Spasovska",
      cidade: "Skopje",
      foto: danielaMacedonia
    },
    {
      nome: "Bryan Hernandez Ortiz",
      cidade: "Costa Rica",
      foto: bryanCostaRica
    },
    {
      nome: "Lina Pepe",
      cidade: "Calgary",
      foto: linaCanada
    },
    {
      nome: "Sandra Castro Pinzón",
      cidade: "Bogotá",
      foto: sandraColombia
    },
    {
      nome: "Mwaaba Mugala",
      cidade: "Lusaka",
      foto: mwaabaZambia
    },
    {
      nome: "Tutaleni Ilonga",
      cidade: "Windhoek",
      foto: tutaleniNamibia
    },
    {
      nome: "Ido Adler",
      cidade: "Israel",
      foto: idoIsrael
    },
    {
      nome: "David Olamide",
      cidade: "Ile-Ife ",
      foto: davidNigeria
    },
    {
      nome: "Lauryn Robson",
      cidade: "Sandton",
      foto: laurynAfrica
    },
    {
      nome: "Ronica Singh",
      cidade: "Durban",
      foto: ronicaAfrica
    },
    {
      nome: "Duarte Duque",
      cidade: "Barcelos",
      foto: duartePortugal
    },
    {
      nome: "António Durão",
      cidade: "Figueira da Foz",
      foto: antonioPortugal
    },
    {
      nome: "Rodrigo Antunes",
      cidade: "Lisboa",
      foto: rodrigoLisboa
    },
    {
      nome: "Ivandro Ribeiro",
      cidade: "Cabo Verde",
      foto: ivandroCaboVerde
    },
    {
      nome: "Yousef BuHazza",
      cidade: "Bahrain",
      foto: yousefBahrain
    },
    {
      nome: "Harriet Bartlett",
      cidade: "France",
      foto: harrietFrance
    },
    {
      nome: "Aymeric Armougom-Aynou",
      cidade: "France",
      foto: aymericFrance
    },
    {
      nome: "Cédric Prudent",
      cidade: "France",
      foto: cedricFrance
    },
    {
      nome: "Isabel Pink Bear",
      cidade: "Playa del Carmen",
      foto: isabelPlaya
    },
    {
      nome: "Silvani Neri",
      cidade: "Salvador",
      foto: silvaniNeri
    },
    {
      nome: "Ricardo Silva",
      cidade: "Salvador",
      foto: ricardoSalvador
    },
    {
      nome: "Melina Juraski",
      cidade: "Salvador",
      foto: melinaSalvador
    },
    {
      nome: "Cecília Santos",
      cidade: "Salvador",
      foto: ceciliaSalvador
    },
    {
      nome: "Klébia Karina",
      cidade: "Natal",
      foto: klebiaNatal
    },
    {
      nome: "Danielle Akemi Jogo",
      cidade: "Uberlândia",
      foto: danielleAkemi
    },
    {
      nome: "Marcelo Anselmo Gomes",
      cidade: "Belo Horizonte",
      foto: marceloAnselmo
    },
    {
      nome: "Bianca Pacheco",
      cidade: "Brasília",
      foto: biancaPacheco
    },
    {
      nome: "Nayara Raquel",
      cidade: "Caruaru",
      foto: nayaraRaquel
    },
    {
      nome: "Cael Borges",
      cidade: "Maranhão",
      foto: caelBorges
    },
    {
      nome: "Matheus Borba",
      cidade: "Goiânia",
      foto: matheusBorba
    },
    {
      nome: "Rafael Passos",
      cidade: "Uberaba",
      foto: rafaelPassos
    },
    {
      nome: "Karina Santos",
      cidade: "Uberaba",
      foto: karinaUberaba
    },
    {
      nome: "Júlia Volpato",
      cidade: "Liga Sul de Minas",
      foto: juliaMinas
    },
    {
      nome: "Caio Vicente Rodrigues",
      cidade: "Patos de Minas",
      foto: caioPatos
    },
    {
      nome: "Alberto Miranda",
      cidade: "Distrito Federal",
      foto: albertoDF
    },
    {
      nome: "Carlos Tapudima",
      cidade: "Manaus",
      foto: carlosManaus
    },
    {
      nome: "Isabelly Rohana",
      cidade: "Manaus",
      foto: isabellyManaus
    },
    {
      nome: "Jonh Silva",
      cidade: "Manaus",
      foto: johnManaus
    },
    {
      nome: "Davi Bennier",
      cidade: "Manaus",
      foto: daviBennier
    },
    {
      nome: "Welinton Faria",
      cidade: "Liga Sul de Minas",
      foto: welintonMinas
    },
    {
      nome: "Anny Caroline Sousa",
      cidade: "Engenheiro Paulo de Frontin",
      foto: annyPauloFrontim
    },
    {
      nome: "Karina Gomes de Siqueira",
      cidade: "Engenheiro Paulo de frontin",
      foto: karinaPauloFrontim
    },
    {
      nome: "Alice Castanheira",
      cidade: "Engenheiro Paulo de frontin",
      foto: alicePauloFrontim
    },
    {
      nome: "Anna Loureiro",
      cidade: "Engenheiro Paulo de frontin",
      foto: annaPauloFrontim
    },
    {
      nome: "Ivan Sendin",
      cidade: "Rio Grande do Sul",
      foto: ivanRS
    },
    // {
    //   nome: "Pam Silva",
    //   cidade: "Recife",
    //   foto: pamRecife
    // },
    {
      nome: "Beatriz Cabral",
      cidade: "Recife",
      foto: beatrizRecife
    },
    {
      nome: "Rafael Nasper",
      cidade: "Recife",
      foto: rafaelRecife
    },
    {
      nome: "Daniele Brandão",
      cidade: "Recife",
      foto: danieleRecife
    },
    {
      nome: "Gabriel Santos Nunes",
      cidade: "Assis",
      foto: gabrielAssis
    },
    {
      nome: "João Pedro Rebouças",
      cidade: "São José de Ribamar",
      foto: joaoRibamar
    },
    {
      nome: "Marco Yerco M. Cabrera",
      cidade: "Porto Velho",
      foto: marcoPortoVelho
    },
    {
      nome: "Vinicius Lima",
      cidade: "Fortaleza",
      foto: viniciusFortaleza
    },
    {
      nome: "Daniel Caldas",
      cidade: "Aracaju",
      foto: danielAracaju
    },
    {
      nome: "Humberto Garcia",
      cidade: "Florianópolis",
      foto: humbertoFloripa
    },
    {
      nome: "BRUNO CAMPAGNOLO DE PAULA",
      cidade: "Curitiba",
      foto: brunoCuritiba
    },
    {
      nome: "Vinicius Yokomizo Pires",
      cidade: "São Paulo",
      foto: viniciusSP
    },
    {
      nome: "Khelson Farias",
      cidade: "Belém",
      foto: khelsonBelem
    },
    {
      nome: "Luis Felipe Patrocinio",
      cidade: "Teresina",
      foto: luisTeresina
    },
    
  ]
}

export default dados