// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import gabriel from '../../assets/images/organizadores/2019/Gabriel.jpg'
import bernardo from '../../assets/images/organizadores/2017/bernardo.jpg'
import erickGlobal from '../../assets/images/organizadores/2017/erick.jpg'

//Fotos Regional
import guilherme from '../../assets/images/organizadores/2018/guilherme.jpg'
import brunoPoA from '../../assets/images/organizadores/2017/bruno.jpg'
import thiago from '../../assets/images/organizadores/2017/thiago.jpg'
import marcos from '../../assets/images/organizadores/2017/marcos.jpg'
import gaming from '../../assets/images/organizadores/2017/gaming.jpeg'

const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, {
      nome: "Gabriel Ferreira",
      foto: gabriel
    }, {
      nome: "Bernardo Menezes",
      foto: bernardo
    }, {
      nome: "Erick Najjar",
      foto: erickGlobal
    }
  ],
  regional: [
    {
      nome: "Guilherme Oliveira",
      cidade: "Brasília",
      foto: guilherme
    },
    {
      nome: "Bruno Pilgrim",
      cidade: "Porto Alegre",
      foto: brunoPoA
    }, {
      nome: "Marcos Gonçalves",
      cidade: "São Paulo",
      foto: marcos
    },
    {
      nome: "Thiago Canfield",
      cidade: "Curitiba",
      foto: thiago
    },
    {
      nome: "GAMinG",
      cidade: "Belo Horizonte",
      foto: gaming
    }
  ]
}

export default dados