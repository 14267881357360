// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import gabriel from '../../assets/images/organizadores/2019/Gabriel.jpg'
import herbert from '../../assets/images/organizadores/aqgj/Herbert.jpg'
import anna from '../../assets/images/organizadores/aqgj/Anna.jpg'
import leo from '../../assets/images/organizadores/aqgj/Leo.jpeg'
import higor from '../../assets/images/organizadores/aqgj/Higor.jpg'
import joao from '../../assets/images/organizadores/aqgj/Joao.jpeg'

const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, {
      nome: "Gabriel Ferreira",
      foto: gabriel
    }, {
      nome: "Anna Gianneli",
      foto: anna
    }, {
      nome: "Herbert Veloso",
      foto: herbert
    }, {
      nome: "Leonardo Mendes",
      foto: leo
    }, {
      nome: "João Silveira",
      foto: joao
    }, {
      nome: "Higor Bagetti",
      foto: higor
    }
  ],
  regional: []
}

export default dados