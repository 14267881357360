import React from 'react';
import styled from 'styled-components'

const Organizador = ({ nome, foto, cidade, regional }) => {

  const OrganizadorContent = styled.div`
    width: 260px;
    margin: 0 20px 48px;
  `

  const OrganizadorImageBox = styled.div`
    padding: 8px;
    border-style: solid;
    border-width: 8px;
    border-color: ${regional ? 'var(--pink)' : 'var(--purple)' };
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  const OrganizadorImage = styled.img`
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `

  const OrganizadorBox = styled.div`
    width: 100%;
    padding: 16px 8px;
    background: ${regional ? 'var(--pink)' : 'var(--purple)' };
    border-radius: 12px;
    color: var(--white);
    text-align: center;
    position: relative;
    top: -32px;
  `

  const OrganizadorName = styled.h3`
    margin-bottom: 6px;
    font-family: var(--primary);
    font-size: 21px;
  `

  const OrganizadorCity = styled.p`
    font-family: var(--primaryLight);
    font-size: 18px;
  `

  return (
    <OrganizadorContent>
      <OrganizadorImageBox>
        <OrganizadorImage className="organizer__img" style={{ backgroundImage: `url(${foto})` }} />
      </OrganizadorImageBox>
      <OrganizadorBox className='organizer__text'>
        <OrganizadorName>{nome}</OrganizadorName>
        <OrganizadorCity>{cidade === undefined ? "Global" : cidade}</OrganizadorCity>
      </OrganizadorBox>
    </OrganizadorContent>
  )
}

export default Organizador