// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import lucas from '../../assets/images/organizadores/2019/Lucas.jpg'
import gabriel from '../../assets/images/organizadores/2019/Gabriel.jpg'

//Fotos Regional
import pedrozambon from '../../assets/images/organizadores/2018/pedro.jpg'
import larissa from '../../assets/images/organizadores/2018/larissa.jpg'
import guilherme from '../../assets/images/organizadores/2018/guilherme.jpg'
import marcello from '../../assets/images/organizadores/2018/marcello.jpg'
import arison from '../../assets/images/organizadores/2018/arison.jpg'
import tiago from '../../assets/images/organizadores/2018/tiago.jpg'
import harry from '../../assets/images/organizadores/2018/harry.jpg'
import pamela from '../../assets/images/organizadores/2019/PamelaCuritiba.jpg'
import danielAracaju from '../../assets/images/organizadores/2019/DanielAracaju.jpg'
import khelson from '../../assets/images/organizadores/2019/KhelsonBelem.jpg'
import jose from '../../assets/images/organizadores/2019/JoseSaoLuis.jpg'
import danilo from '../../assets/images/organizadores/2019/DaniloTeresina.jpg'
import felipe from '../../assets/images/organizadores/2019/FelipePatosDeMinas.jpg'
import narto from '../../assets/images/organizadores/2019/NartoNatal.jpg'

const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, {
      nome: "Lucas Pessoa",
      foto: lucas
    }, {
      nome: "Gabriel Ferreira",
      foto: gabriel
    }
  ],
  regional: [
    {
      nome: "Pedro Zambon",
      cidade: "São Paulo",
      foto: pedrozambon
    }, {
      nome: "Larissa Rios",
      cidade: "Brasília",
      foto: larissa
    }, {
      nome: "Guilherme Oliveira",
      cidade: "Brasília",
      foto: guilherme
    }, {
      nome: "Pâmela Beltrani",
      cidade: "Curitiba",
      foto: pamela
    }, {
      nome: "Marcello Lima",
      cidade: "Florianópolis",
      foto: marcello
    }, {
      nome: "Daniel Caldas",
      cidade: "Aracaju",
      foto: danielAracaju
    }, {
      nome: "Khelson Farias",
      cidade: "Belém",
      foto: khelson
    }, {
      nome: "Arison Heltami",
      cidade: "Fortaleza",
      foto: arison
    }, {
      nome: "Tiago Fernando",
      cidade: "Natal",
      foto: tiago
    }, {
      nome: "José Nunes",
      cidade: "São Luís",
      foto: jose
    }, {
      nome: "Danilo Carvalho",
      cidade: "Teresina",
      foto: danilo
    }, {
      nome: "Harry Florencio",
      cidade: "Recife",
      foto: harry
    }, {
      nome: "Felipe Alvarenga",
      cidade: "Patos de Minas",
      foto: felipe
    }, {
      nome: "Narto Júnior",
      cidade: "Natal",
      foto: narto
    }
  ]
}

export default dados