import React from 'react'
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"
import Organizador from "../components/Organizador"

//Texts
import texts from "../components/Organizadores/content"
import ano21 from "../components/Organizadores/ano21"
import ano20 from "../components/Organizadores/ano20"
import aqgj from "../components/Organizadores/aqgj"
import ano19 from "../components/Organizadores/ano19"
import ano18 from "../components/Organizadores/ano18"
import ano17 from "../components/Organizadores/ano17"



const getYear = () => {
  let url = undefined
  if (typeof window !== 'undefined') url = window.location.search.split('?')[1];

  if (url === "2021") {
    return ano21
  } else if (url === "2020") {
    return ano20
  } else if (url === "AQGJ+") {
    return aqgj
  } else if (url === "2019") {
    return ano19
  } else if (url === "2018") {
    return ano18
  } else if (url === "2017") {
    return ano17
  } else {
    return ano21
  }
}

const Organizadores = () => {
  const evento = getYear()

  return (
    <Layout>
      <Seo title="Organização" />
      <OrganizadoresContainer>
        <TitleGlobal>{Language(texts).geral}</TitleGlobal>
        <OrganizadoresContent >
          {evento.geral.map(
            (item, index) => {
              return (
                <Organizador
                  foto={item.foto}
                  nome={item.nome}
                  key={index}
                />
              );
            }
          )}
        </OrganizadoresContent>
      </OrganizadoresContainer>
      {(typeof window !== 'undefined') ? window.location.search.split('?')[1] === "AQGJ+" ? null :
        <OrganizadoresContainer>
          <TitleRegional>{Language(texts).regional}</TitleRegional>
          <OrganizadoresContent>
            {evento.regional.map(
              (item, index) => {
                return (
                  <Organizador
                    foto={item.foto}
                    cidade={item.cidade}
                    nome={item.nome}
                    regional
                    key={index}
                  />);
              }
            )}
          </OrganizadoresContent>
        </OrganizadoresContainer>
        : null
      }
    </Layout>
  )
}

export default Organizadores

const OrganizadoresContainer = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OrganizadoresContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
`

const TitleGlobal = styled(Title)`
  color: var(--purple);
`

const TitleRegional = styled(Title)`
  color: var(--purple);
`