// Fotos Global
import ian from '../../assets/images/organizadores/2019/Ian.jpg'
import herbert from '../../assets/images/organizadores/aqgj/Herbert.jpg'
import anna from '../../assets/images/organizadores/aqgj/Anna.jpg'
import leo from '../../assets/images/organizadores/aqgj/Leo.jpeg'
import leoBerman from '../../assets/images/organizadores/2020/leoBerman.jpg'
import leoMartins from '../../assets/images/organizadores/2020/leoMartins.jpg'
import joaoLucas from '../../assets/images/organizadores/2020/joaoLucas.jpg'
import daviBennier from '../../assets/images/organizadores/2020/daviBennier.png'
import jeanine from '../../assets/images/organizadores/2020/jeanine.jpeg'
import cassio from '../../assets/images/organizadores/2020/cassio.jpeg'

//Fotos Regional
import leonardo from '../../assets/images/organizadores/2020/leonardo.jpg'
import felippePortoVelho from '../../assets/images/organizadores/2020/felippePortoVelho.jpg'
import joshJones from '../../assets/images/organizadores/2020/joshJones.jpeg'
import chuboNamibia from '../../assets/images/organizadores/2020/chuboNamibia.jpeg'
import rodrigoLisboa from '../../assets/images/organizadores/2020/rodrigoLisboa.jpg'
import mwaabaLusaka from '../../assets/images/organizadores/2020/mwaabaLusaka.jpg'
import ravihansSriLAnka from '../../assets/images/organizadores/2020/ravihansSriLanka.jpg'
import lahiruSriLAnka from '../../assets/images/organizadores/2020/lahiruSriLanka.jpg'
import yasithSriLAnka from '../../assets/images/organizadores/2020/yasithSriLanka.jpg'
import raphaelRJ from '../../assets/images/organizadores/2020/raphaelRJ.jpg'
import marceloBH from '../../assets/images/organizadores/2020/marceloBH.jpg'
import rafaiaBH from '../../assets/images/organizadores/2020/rafaiaBH.jpg'
import viniciusFortaleza from '../../assets/images/organizadores/2020/viniciusFortaleza.jpg'
import ismaelFortaleza from '../../assets/images/organizadores/2020/ismaelFortaleza.jpg'
import juliRS from '../../assets/images/organizadores/2020/juliRS.jpg'
import gabrielBSB from '../../assets/images/organizadores/2020/gabrielBSB.jpg'
import lucasBSB from '../../assets/images/organizadores/2020/lucasBSB.jpg'
import gabrielMotaBSB from '../../assets/images/organizadores/2020/gabrielMotaBSB.jpg'
import isaacBSB from '../../assets/images/organizadores/2020/isaacBSB.jpg'
import guilhermeBSB from '../../assets/images/organizadores/2020/guilhermeBSB.jpg'
// import palomaRecife from '../../assets/images/organizadores/2020/palomaRecife.jpg'
import erickPalmas from '../../assets/images/organizadores/2020/erickPalmas.jpg'
import carlosManaus from '../../assets/images/organizadores/2020/carlosManaus.jpg'
import klebiaNatal from '../../assets/images/organizadores/2020/klebiaNatal.jpg'
import germanoParnaiba from '../../assets/images/organizadores/2020/germanoParnaiba.jpg'
import felipeCostaRica from '../../assets/images/organizadores/2020/felipeCostaRica.jpeg'
import thamyresRecife from '../../assets/images/organizadores/2020/thamyresRecife.jpg'
import shawnMalasia from '../../assets/images/organizadores/2020/shawnMalasia.jpeg'
import marten from '../../assets/images/organizadores/2020/marten.jpeg'
import mikaMadagascar from '../../assets/images/organizadores/2020/mikaMadagascar.jpeg'
import rodolfoSanJose from '../../assets/images/organizadores/2020/rodolfoSanJose.jpeg'
import andresSanJose from '../../assets/images/organizadores/2020/andresSanJose.jpeg'
import alexanderKansas from '../../assets/images/organizadores/2020/alexanderKansas.jpeg'
import antonioPortugal from '../../assets/images/organizadores/2020/antonioPortugal.jpeg'
import johnManaus from '../../assets/images/organizadores/2020/johnManaus.jpeg'
import yasminManaus from '../../assets/images/organizadores/2020/yasminManaus.jpeg'
import yanuJakarta from '../../assets/images/organizadores/2020/yanuJakarta.jpeg'
import mochammadIndonesia from '../../assets/images/organizadores/2020/mochammadIndonesia.jpeg'
import barliIndonesia from '../../assets/images/organizadores/2020/barliIndonesia.jpeg'
import adhiemasIndonesia from '../../assets/images/organizadores/2020/adhiemasIndonesia.jpeg'
import anrafelVassouras from '../../assets/images/organizadores/2020/anrafelVassouras.jpeg'
import fabioVassouras from '../../assets/images/organizadores/2020/fabioVassouras.jpeg'
import matheusVassouras from '../../assets/images/organizadores/2020/matheusVassouras.jpeg'
import thamiresVassouras from '../../assets/images/organizadores/2020/thamiresVassouras.jpeg'
import guilhermeVassouras from '../../assets/images/organizadores/2020/guilhermeVassouras.jpeg'
import welintonItajuba from '../../assets/images/organizadores/2020/welintonItajuba.jpg'
import annyPauloDeFrontin from '../../assets/images/organizadores/2020/annyPauloDeFrontin.jpeg'
import gwenSingapura from '../../assets/images/organizadores/2020/gwenSingapura.jpeg'
import ryoOkinawa from '../../assets/images/organizadores/2020/ryoOkinawa.jpeg'
import masatoOkinawa from '../../assets/images/organizadores/2020/masatoOkinawa.jpeg'
import tauSkovde from '../../assets/images/organizadores/2020/tauSkovde.jpeg'
import karinaEngPaulo from '../../assets/images/organizadores/2020/karinaEngPaulo.jpg'
import abhimanyuIndia from '../../assets/images/organizadores/2020/abhimanyuIndia.jpeg'
import aviroopIndia from '../../assets/images/organizadores/2020/aviroopIndia.jpeg'
import bhuvaneshIndia from '../../assets/images/organizadores/2020/bhuvaneshIndia.jpeg'
import jayIndia from '../../assets/images/organizadores/2020/jayIndia.jpeg'
import nishantIndia from '../../assets/images/organizadores/2020/nishantIndia.jpeg'
import tanishkaIndia from '../../assets/images/organizadores/2020/tanishkaIndia.jpeg'
import vinayIndia from '../../assets/images/organizadores/2020/vinayIndia.jpeg'
import sandraMexico from '../../assets/images/organizadores/2020/sandraMexico.jpeg'
import margaritaSantiago from '../../assets/images/organizadores/2020/margaritaSantiago.jpg'
import idoIsrael from '../../assets/images/organizadores/2020/idoIsrael.jpeg'
import raphaelEngPaulo from '../../assets/images/organizadores/2020/raphaelEngPaulo.jpeg'
import danielAracaju from '../../assets/images/organizadores/2019/DanielAracaju.jpg'
import khelson from '../../assets/images/organizadores/2019/KhelsonBelem.jpg'
import gustavo from '../../assets/images/organizadores/2019/GustavoGoiania.jpg'
import caio from '../../assets/images/organizadores/2019/CaioUberaba.jpg'
import felipe from '../../assets/images/organizadores/2019/FelipePatosDeMinas.jpg'
import narto from '../../assets/images/organizadores/2019/NartoNatal.jpg'
import filipeCaruaru from '../../assets/images/organizadores/2019/FilipeCaruaru.jpg'
import italoFortaleza from '../../assets/images/organizadores/2019/ItaloFortaleza.jpg'
import hugoPorto from '../../assets/images/organizadores/2019/HugoPorto.jpg'
import ronica from '../../assets/images/organizadores/2019/Ronica.jpg'
import ivanPortoAlegre from '../../assets/images/organizadores/2019/ivan.jpg'
import viniciusSaoPaulo from '../../assets/images/organizadores/2019/ViniciusSaoPaulo.png'
import sergioCochabamba from '../../assets/images/organizadores/2019/SergioCochabamba.jpg'
import daniloPresidentePrudente from '../../assets/images/organizadores/2019/DaniloPresidentePrudente.jpg'
import kledsonMaceio from '../../assets/images/organizadores/2019/kledsonMaceio.png'
import tutaleniNamibia from '../../assets/images/organizadores/2019/tutaleniNamibia.jpg'
import tiago from '../../assets/images/organizadores/2018/tiago.jpg'

const dados = {
  geral: [
    {
      nome: "Ian Rochlin",
      foto: ian
    }, {
      nome: "Anna Gianneli",
      foto: anna
    }, {
      nome: "Herbert Veloso",
      foto: herbert
    }, {
      nome: "Leonardo Mendes",
      foto: leo
    }, {
      nome: "Leonardo Berman",
      foto: leoBerman
    }, {
      nome: "Leonardo Martins",
      foto: leoMartins
    }, {
      nome: "João Lucas",
      foto: joaoLucas
    }, {
      nome: "Davi Bennier",
      foto: daviBennier
    }, {
      nome: "António Durão",
      foto: antonioPortugal
    }, {
      nome: "Klébia Karina",
      foto: klebiaNatal
    }, 
    // {
    //   nome: "Paloma Silva",
    //   foto: palomaRecife
    // }, 
    {
      nome: "Italo Furtado",
      foto: italoFortaleza
    }, {
      nome: "Felipe Alvarenga",
      foto: felipe
    }, {
      nome: "Jeanine Adler",
      foto: jeanine
    }, {
      nome: "Cássio Amaral",
      foto: cassio
    },
  ],
  regional: [
    {
      nome: "Rodrigo Antunes",
      cidade: "Lisboa",
      foto: rodrigoLisboa
    }, {
      nome: "Hugo Lopes",
      cidade: "Porto",
      foto: hugoPorto
    }, {
      nome: "António Durão",
      cidade: "Figueira da Foz",
      foto: antonioPortugal
    }, {
      nome: "Felipe Cartin",
      cidade: "Costa Rica",
      foto: felipeCostaRica
    }, {
      nome: "Sergio Arispe",
      cidade: "Cochabamba",
      foto: sergioCochabamba
    }, {
      nome: "Ronica Singh",
      cidade: "Durban",
      foto: ronica
    }, {
      nome: "Tutaleni Ilonga",
      cidade: "Windhoek",
      foto: tutaleniNamibia
    }, {
      nome: "Chubo Zeko",
      cidade: "Windhoek",
      foto: chuboNamibia
    }, {
      nome: "Mwaaba Alec",
      cidade: "Lusaka",
      foto: mwaabaLusaka
    }, {
      nome: "Josh Jones",
      cidade: "Salt Lake City",
      foto: joshJones
    }, {
      nome: "Ravihans Wetakepotha",
      cidade: "Colombo",
      foto: ravihansSriLAnka
    }, {
      nome: "Lahiru Rajapaksha",
      cidade: "Colombo",
      foto: lahiruSriLAnka
    }, {
      nome: "Yasith Sirisena",
      cidade: "Colombo",
      foto: yasithSriLAnka
    }, {
      nome: "Yanu Chandra",
      cidade: "Jakarta",
      foto: yanuJakarta
    }, {
      nome: "Mochammad Rizal",
      cidade: "Yogyakarta",
      foto: mochammadIndonesia
    }, {
      nome: "Barli Jabbar",
      cidade: "Bandung",
      foto: barliIndonesia
    }, {
      nome: "Adhiemas Andira",
      cidade: "Surabaya",
      foto: adhiemasIndonesia
    }, {
      nome: "Alexander Crane",
      cidade: "Kansas City",
      foto: alexanderKansas
    }, {
      nome: "Rodolfo Mora-Zamora",
      cidade: "San José",
      foto: rodolfoSanJose
    }, {
      nome: "Andres Cartin",
      cidade: "San José",
      foto: andresSanJose
    }, {
      nome: "Mika Raharison",
      cidade: "Antananarivo",
      foto: mikaMadagascar
    }, {
      nome: "Mårten",
      cidade: "Stockholm",
      foto: marten
    }, {
      nome: "Shawn Beck",
      cidade: "Kuala Lumpur",
      foto: shawnMalasia
    }, {
      nome: "Gwen Guo",
      cidade: "Singapura",
      foto: gwenSingapura
    }, {
      nome: "Masato Tawata",
      cidade: "Okinawa",
      foto: masatoOkinawa
    }, {
      nome: "Ryo Yasuda",
      cidade: "Okinawa",
      foto: ryoOkinawa
    }, {
      nome: "Tau Petersson",
      cidade: "Skövde",
      foto: tauSkovde
    }, {
      nome: "Abhimanyu",
      cidade: "India",
      foto: abhimanyuIndia
    }, {
      nome: "Aviroop Roy",
      cidade: "India",
      foto: aviroopIndia
    }, {
      nome: "Bhuvanesh Tekavade",
      cidade: "India",
      foto: bhuvaneshIndia
    }, {
      nome: "Jay Sampat",
      cidade: "India",
      foto: jayIndia
    }, {
      nome: "Nishant Bhokare",
      cidade: "India",
      foto: nishantIndia
    }, {
      nome: "Tanishka",
      cidade: "India",
      foto: tanishkaIndia
    }, {
      nome: "Vinay Kumar",
      cidade: "India",
      foto: vinayIndia
    }, {
      nome: "Sandra Barrón",
      cidade: "México",
      foto: sandraMexico
    }, {
      nome: "Margarita Pino",
      cidade: "Santiago",
      foto: margaritaSantiago
    }, {
      nome: "Ido Adler",
      cidade: "Tel Aviv",
      foto: idoIsrael
    }, {
      nome: "Raphael Sanguinete",
      cidade: "Rio de Janeiro",
      foto: raphaelRJ
    }, {
      nome: "Felipe Alvarenga",
      cidade: "Patos de Minas",
      foto: felipe
    }, {
      nome: "Ivan Sendin",
      cidade: "Porto Alegre",
      foto: ivanPortoAlegre
    }, {
      nome: "Vinicius Yokomizo",
      cidade: "São Paulo",
      foto: viniciusSaoPaulo
    }, {
      nome: "Marcelo Gomes",
      cidade: "Belo Horizonte",
      foto: marceloBH
    }, {
      nome: "Rafaia Malta",
      cidade: "Belo Horizonte",
      foto: rafaiaBH
    }, {
      nome: "Italo Furtado",
      cidade: "Fortaleza",
      foto: italoFortaleza
    }, {
      nome: "Vinicius Lima",
      cidade: "Fortaleza",
      foto: viniciusFortaleza
    }, {
      nome: "Ismael Maciel",
      cidade: "Fortaleza",
      foto: ismaelFortaleza
    }, {
      nome: "Caio Melo",
      cidade: "Uberaba",
      foto: caio
    }, {
      nome: "Filipe Rafael",
      cidade: "Caruaru",
      foto: filipeCaruaru
    }, {
      nome: "Narto Júnior",
      cidade: "Natal",
      foto: narto
    }, {
      nome: "Tiago Sousa",
      cidade: "Natal",
      foto: tiago
    }, {
      nome: "Klébia Karina",
      cidade: "Natal",
      foto: klebiaNatal
    }, {
      nome: "Juli Stracke",
      cidade: "Novo Hamburgo",
      foto: juliRS
    }, {
      nome: "Leonardo Lindoso",
      cidade: "São Luís",
      foto: leonardo
    }, {
      nome: "Daniel Caldas",
      cidade: "Aracaju",
      foto: danielAracaju
    }, {
      nome: "Gustavo Christino",
      cidade: "Goiânia",
      foto: gustavo
    }, {
      nome: "Gabriel Andrade",
      cidade: "Brasília",
      foto: gabrielBSB
    }, {
      nome: "Lucas Caldeira",
      cidade: "Brasília",
      foto: lucasBSB
    }, {
      nome: "Gabriel Mota",
      cidade: "Brasília",
      foto: gabrielMotaBSB
    }, {
      nome: "Isaac Lopes",
      cidade: "Brasília",
      foto: isaacBSB
    }, {
      nome: "Guilherme Okita",
      cidade: "Brasília",
      foto: guilhermeBSB
    }, {
      nome: "Felippe Malagueta",
      cidade: "Porto Velho",
      foto: felippePortoVelho
    }, 
    // {
    //   nome: "Paloma Silva",
    //   cidade: "Recife",
    //   foto: palomaRecife
    // }, 
    {
      nome: "Kledson Ferreira",
      cidade: "Maceió",
      foto: kledsonMaceio
    }, {
      nome: "Erick Góes",
      cidade: "Palmas",
      foto: erickPalmas
    }, {
      nome: "Carlos Tapudima",
      cidade: "Manaus",
      foto: carlosManaus
    }, {
      nome: "Germano Gomes",
      cidade: "Parnaíba",
      foto: germanoParnaiba
    }, {
      nome: "Khelson Farias",
      cidade: "Belém",
      foto: khelson
    }, {
      nome: "Danilo Filitto",
      cidade: "Presidente Prudente",
      foto: daniloPresidentePrudente
    }, {
      nome: "Thamyres Oliveira",
      cidade: "Recife",
      foto: thamyresRecife
    }, {
      nome: "John Silva",
      cidade: "Manaus",
      foto: johnManaus
    }, {
      nome: "Yasmin Rodrigues",
      cidade: "Manaus",
      foto: yasminManaus
    }, {
      nome: "Anrafel Fernandes",
      cidade: "Vassouras",
      foto: anrafelVassouras
    }, {
      nome: "Fábio Goncalves",
      cidade: "Vassouras",
      foto: fabioVassouras
    }, {
      nome: "Matheus Telles",
      cidade: "Vassouras",
      foto: matheusVassouras
    }, {
      nome: "Thamires Lemes",
      cidade: "Vassouras",
      foto: thamiresVassouras
    }, {
      nome: "Guilherme Delgado",
      cidade: "Vassouras",
      foto: guilhermeVassouras
    }, {
      nome: "Welinton",
      cidade: "Itajubá",
      foto: welintonItajuba
    }, {
      nome: "Anny Sousa",
      cidade: "Engenheiro Paulo de Frontin",
      foto: annyPauloDeFrontin
    }, {
      nome: "Karina Siqueira",
      cidade: "Engenheiro Paulo de Frontin",
      foto: karinaEngPaulo
    }, {
      nome: "Raphael Lourenço",
      cidade: "Engenheiro Paulo de Frontin",
      foto: raphaelEngPaulo
    },
  ]
}

export default dados